<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="false"
      background-color="#FFFFFF"
      color="#FF4343"
      spinner="bar-fade-scale"
    />
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to delete this Hive ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteHive"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <v-layout wrap justify-center fill-height>
                <v-flex xs12 sm2 md1 xl2 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <v-avatar color="#FFF9C4" size="70">
                        <v-img
                          v-if="storage.photo"
                          :src="mediaURL + storage.photo"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#FDCE48"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <span class="itemKey" v-else>No Image</span>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-card color="red" v-if="storage.islate" pt-2>
                        <span class="itemKey" style="color: #ffffff">Late</span>
                      </v-card>
                      <v-card color="green" tile flat v-else>
                        <span class="itemKey" style="color: #ffffff">Ok</span>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm10 md8 xl10 pl-2 align-self-end>
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Bee Keeper</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.user.username }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Hive Id</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.colonyidentifier }}
                            <span v-if="!storage.colonyidentifier">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Hive Status</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.colonystatus }}
                            <span v-if="!storage.colonystatus">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Source</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.source }}
                            <span v-if="!storage.source">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Honey Type</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.honeytype }}
                            <span v-if="!storage.honeytype">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Created Date</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ formatDate(storage.createddate) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 md3 xl12 text-right align-self-center>
                  <v-layout wrap justify-end>
                    <v-flex xs6 sm3 md12 xl2 pa-2>
                      <v-btn
                        block
                        tile
                        small
                        depressed
                        color="#1885f2"
                        :ripple="false"
                        :to="'/Hive/' + storage._id"
                        class="itemValue"
                      >
                        <span style="color: #ffffff"> View </span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 sm3 md12 xl2 pa-2>
                      <v-btn
                        block
                        outlined
                        tile
                        small
                        depressed
                        color="#FF1313"
                        :ripple="false"
                        @click="deleteDialogue = true"
                        class="itemValue"
                      >
                        Delete
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      deleteDialogue: false,
    };
  },
  methods: {
    deleteHive() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/colony/adminremove/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>